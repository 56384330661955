<template>
  <div class="main-page">
    <!-- 条件搜索部分 -->
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row " style="display: flex">
        <el-form-item label-width="70px" label="开展年份：">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width:200px !important" v-model="msForm.certificateDate" value-format="yyyy" type="year" placeholder="请选择" />
        </el-form-item>
        <el-form-item label-width="70px" label="任务主题：">
          <el-input style="width: 300px !important" class="zwx-input" v-model.trim="msForm.organName" placeholder="请输入" clearable />
        </el-form-item>
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">添加</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addSelect">企业遴选</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="detail">查看</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%" row-key="rid" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="zoneName" label="开展年份" width="180" header-align="center" align="left"></el-table-column>
      <el-table-column prop="organName" label="任务主题" width="300" header-align="center" align="left"></el-table-column>
      <el-table-column prop="creditCode" label="通知文件" width="200" header-align="center" align="center"></el-table-column>
      <el-table-column prop="linkMan" label="发布单位" width="100" header-align="center" align="center"></el-table-column>
      <el-table-column prop="date" label="发布日期" width="120" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ $system.formatDate(row.date, 'YYYY-MM-DD') || '-' }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="$system.calMinWidth({ wordNum: 4, arrowNum: 2, btnNum: 2 })" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="detail(scope.row)">查看</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="edit(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', delRecord)">删除</el-button>
        </template>
      </el-table-column>
      <empty-data-div slot="empty" />
    </el-table>
    <!-- 分页器部分 -->
    <base-pagination class="normal-pagination" :parentPage="msForm.currentPage" :pageSize="pageSize" :total="total" @currentChange="currentChange" />
  </div>
</template>

<script>
export default {
  name: 'UnitHelpList',
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      msForm: {
        zoneCode: '',
        organName: '',
        serviceSorts: [],
        currentPage: 1,
      },
      dataList: [],
      pageSize: 18,
      total: 0,
    }
  },
  mounted() {
    // this.search(this.msForm.currentPage)
  },
  activated() {
    if (this.$route.params.fresh) this.search(this.msForm.currentPage)
  },
  computed: {},
  methods: {
    currentChange(currentPage) {
      this.search(currentPage)
    },
    search(currentPage) {
      this.$emit('loading', true)
      this.msForm.currentPage = currentPage
      this.msForm.zoneCode = this.$refs.areaIdArea.getChooseArea().zoneCode12
      let data = { ...this.msForm }
      this.$system.postJson(this.api + '/oh/repository/getTechServiceBasicList-1', data, true, true, this.searchSuccess, this.error)
    },
    searchSuccess(data) {
      if (data.type === '00') {
        this.dataList = data.techServiceBasicList
        this.total = data.techServiceBasicCount
        this.pageSize = data.pageSize
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    detail(row) {
      // localStorage.removeItem('service_organization.TechServiceBasicDetailIndex.params')
      this.$router.push({ name: 'UnitHelpDetail', params: { rid: row.rid } })
    },
    add() {
      this.$router.push({ name: 'UnitHelpEdit', params: {} })
    },
    addSelect() {
      this.$router.push({ name: 'UnitHelpSelect', params: {} })
    },
    edit(row) {
      localStorage.removeItem('service_organization.TechServiceBasicDetailIndex.params')
      this.$router.push({ name: 'service_organization.TechServiceBasicDetailIndex', params: { rid: row.rid, ifEdit: 1 } })
    },
    delRecord(row) {
      this.$emit('loading', true)
      let data = {
        rid: row.rid,
      }
      this.$system.postJson(this.api + '/oh/repository/deleteTechServiceBasic-1', data, true, true, this.delRecordSuccess, this.error)
    },
    delRecordSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.search(this.msForm.currentPage)
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
  },
}
</script>

<style lang="less" scoped></style>

<style lang="less"></style>
